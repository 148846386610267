import React from "react"
import SectionCall from "../components/organism/about/section-call"
import Layout from "../layout/light-default"
import OurBrand from "../components/organism/about/our-brand.organism"
import AboutHead from "../components/organism/about/about-head.organism"
import Team from "../components/organism/about/team"
import ProductCards from "../components/organism/about/product-cards"
import "../dist/css/slider.css"
import "../dist/css/vendor.css"
import "../dist/css/main.css"
import "../dist/css/about-custom.css"
import db from "../firebase"
import SEO from "../layout/SEO"
import { useState } from "react"
import { useEffect } from "react"
import { getDownloadURL, getStorage, ref } from "firebase/storage"

const About = () => {
  const storage = getStorage();
  const [about, setAbout] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [bannerImage, setBannerImage] = useState('');
  const [brandImage, setBrandImage] = useState('');

  useEffect(() => {
    db.collection('About').onSnapshot(snapshot => {
      setAbout(snapshot.docs.map(doc => ({
        id: doc.id,
        banner_img: doc.data().banner_img,
        canonical_url: doc.data().canonical_url,
        meta_desc: doc.data().meta_desc,
        meta_title: doc.data().meta_title,
        our_brand_img: doc.data().our_brand_img,
        team_members_image: doc.data().team_members_image,
        og_image: doc.data().og_image,
        product_gallery_images: doc.data().product_gallery_images,
        title: doc.data().title
      })))
    })
  }, [])


  useEffect(() => {
    setImage2([]);
    setImage3([])
    about[0] && about[0].team_members_image.push(about[0].banner_img)
    about[0] && about[0].team_members_image.push(about[0].our_brand_img)
    about[0] && about[0].team_members_image.map(image => {
        const imagaRef = ref(storage, image);
        setTimeout(() => {
          getDownloadURL(imagaRef).then(url => {
            if(url.includes("banner")){
              setBannerImage(url);
            }else if(url.includes("brand")){
              setBrandImage(url);
            }else{
              setImage2((prev) => [...prev, url]);
            }
          })
        }, 10);
    })

    about[0] && about[0].product_gallery_images.map(image => {
      const imagaRef = ref(storage, image);
      setTimeout(() => {
        getDownloadURL(imagaRef).then(url => {
          setImage3((prev) => [...prev, url]);
        })
      }, 100);
    })
  }, [about])



  return (
    <>
      {about[0] ? <SEO canonicalUrl={about[0].canonical_url} title={about[0].title} meta_desc={about[0].meta_desc} /> : null}
      <Layout>
        <AboutHead featuredimage={bannerImage} />
        <OurBrand brandimage={brandImage} />
        {/* <Product /> */}
        <Team team_members={image2} manufacture_images={image3} />
        <ProductCards />
        <SectionCall />
      </Layout>
    </>
  )
}

export default About
