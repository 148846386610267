import React from "react"
import styled from "styled-components"
import Head from "../../molecule/about/brand.head.molecule"
import Content from "../../molecule/about/brand.content.molecule"

const SOurBrand = styled.section`
  background: #181818;
`

const OurBrand = ({ brandimage }) => {
  return (
    <SOurBrand>
      <Head brandimage={brandimage} />
      <Content />
    </SOurBrand>
  )
}
export default OurBrand
