import React from "react"
import styled from  "styled-components"

const STeamCard = styled.div`
.figure-member__heading{
  color: #282685;
}
.figure-member__position{
  color:#181818;
}
`
const TeamCard=(props)=>{
  return(  
    <STeamCard className="grid__item grid__item_desktop-4 grid__item_tablet-6 grid__item_mobile-12 grid__item_fluid-3 js-grid__item">
      <div className="figure-member figure-member_has-social">
        <div className={`${props.isTeamMember ? "figure-member__wrapper-img" : null}`}>
          <div className="figure-member__wrapper-img-inner">
            <div className="lazy">
              <img src={props.image} width="300" height="450" alt="team members"/>
            </div>
            {props.isTeamMember && <div className="figure-member__curtain bg-accent-secondary-4"></div>}
          </div>
          {props.isTeamMember && 
            <div className="figure-member__social">
              <ul className="social">
                <li className="social__item"><a className="fa fa-facebook-f" target="blank" href={props.children.fb}></a></li>
               { props.children.twitter ? <li className="social__item"><a className="fa fa-twitter" target="blank" href={props.children.twitter}></a></li> :null}
               { props.children.insta ? <li className="social__item"><a className="fa fa-instagram" target="blank" href={props.children.insta}></a></li> :null}
              { props.children.linkedin ?<li className="social__item"><a className="fa fa-linkedin" target="blank" href={props.children.linkedin}></a></li> :null}
              </ul>
            </div>
          }
          {props.isTeamMember && <div className="figure-member__line"></div>}
        </div>
        {props.isTeamMember && 
          <div className="figure-member__content">
            <h4 className="figure-member__heading">{props.children.name}</h4>
            <div className="figure-member__position">{props.children.postion}</div>
          </div>
        }
      </div>
    </STeamCard>
  )
}

export default TeamCard